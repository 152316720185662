const Cleave = require('./cleave.js')

const checkout = YandexCheckout(process.env.PAYMENT_KEY, { language: 'ru' })
const cardNumberInput = document.getElementById('cardNumber')
const cardExpiredInput = document.getElementById('cardExpired')
const cardCvcInput = document.getElementById('cardCvc')

const errorCardNumber = document.getElementById('invalid_number')
const errorCardExpired = document.getElementById('invalid_expiry_date')
const errorCardCvc = document.getElementById('invalid_cvc')

const yandex = {}

window.addEventListener('message', event => {
  let data = {}
  try {
    data = JSON.parse(event.data)
  } catch (e) {
  }

  if (data.isReadyToPay) {
    createPaymentRequest()
  }
})

function createPaymentRequest(errorDiv) {
  const card = {
    number: cardNumberInput.value,
    cvc: cardCvcInput.value,
    month: cardExpiredInput.value.split('/')[0],
    year: cardExpiredInput.value.split('/')[1],
  }

  checkout.tokenize(card).then(res => {
    if (res.status === 'success') {
      yandex.token = res.data.response.paymentToken
      window.parent.postMessage(JSON.stringify({ paymentToken: yandex.token }), '*')
    } else if (res.error) {
      const { type } = res.error

      if (type === 'validation_error') {
        if (errorDiv) {
          res.error.params.forEach(item => {
            if (errorDiv.id === item.code) {
              errorDiv.innerHTML = item.message
              errorDiv.style.display = 'block'
              errorDiv.parentNode.querySelector('.payment__line').classList.add('payment__line--error')
            }

            if (
              errorDiv.id === 'invalid_expiry_date' &&
              (item.code === 'invalid_expiry_month' || item.code === 'invalid_expiry_year')
            ) {
              errorDiv.innerHTML = item.message
              errorCardExpired.style.display = 'block'
              errorDiv.parentNode.querySelector('.payment__line').classList.add('payment__line--error')
            }
          })
        } else {
          res.error.params.forEach(item => {
            if (item.code === 'invalid_expiry_month' || item.code === 'invalid_expiry_year') {
              errorCardExpired.innerHTML = item.message
              errorCardExpired.style.display = 'block'
              errorCardExpired.parentNode.querySelector('.payment__line').classList.add('payment__line--error')
            } else if (item.code === 'invalid_number') {
              errorCardNumber.innerHTML = item.message
              errorCardNumber.style.display = 'block'
              errorCardNumber.parentNode.querySelector('.payment__line').classList.add('payment__line--error')
            } else {
              errorCardCvc.innerHTML = item.message
              errorCardCvc.style.display = 'block'
              errorCardCvc.parentNode.querySelector('.payment__line').classList.add('payment__line--error')
            }
          })
        }
      } else {
        throw new Error('Something went wrong')
      }
    }
  })
}

function sendOnceStartFillCard() {
  let executed = null
  return function (key) {
    if (!executed) {
      executed = {}
      window.parent.postMessage(JSON.stringify({ cardIsFilling: true }), '*')
    }

    if (!executed[key]) {
      executed[key] = true
      window.parent.postMessage(JSON.stringify({ cardIsFilling: true, target: key }), '*')
    }
  }
}

const startFillCard = sendOnceStartFillCard()

function isCardReady() {
  return cardNumberInput.value.length === 19 &&
    cardCvcInput.value.length === 3 &&
    cardExpiredInput.value.length === 5
}

function cardReadyHandler(e) {
  if (e.target.value) {
    startFillCard(e.target.id)
  }

  if (isCardReady() && !yandex.token) {
    window.parent.postMessage(JSON.stringify({ cardIsReady: true }), '*')
    createPaymentRequest()
  }
}

cardNumberInput.addEventListener('blur', () => createPaymentRequest(errorCardNumber))
cardCvcInput.addEventListener('blur', () => createPaymentRequest(errorCardCvc))
cardExpiredInput.addEventListener('blur', () => createPaymentRequest(errorCardExpired))

cardNumberInput.addEventListener('focus', () => {
  errorCardNumber.style.display = 'none'
  if (errorCardNumber.parentNode.querySelector('.payment__line').classList.contains('payment__line--error')) {
    errorCardNumber.parentNode.querySelector('.payment__line').classList.remove('payment__line--error')
  }
})
cardCvcInput.addEventListener('focus', () => {
  errorCardCvc.style.display = 'none'
  if (errorCardCvc.parentNode.querySelector('.payment__line').classList.contains('payment__line--error')) {
    errorCardCvc.parentNode.querySelector('.payment__line').classList.remove('payment__line--error')
  }
})
cardExpiredInput.addEventListener('focus', () => {
  errorCardExpired.style.display = 'none'
  if (errorCardExpired.parentNode.querySelector('.payment__line').classList.contains('payment__line--error')) {
    errorCardExpired.parentNode.querySelector('.payment__line').classList.remove('payment__line--error')
  }
})

cardNumberInput.addEventListener('keyup', cardReadyHandler)
cardCvcInput.addEventListener('keyup', cardReadyHandler)
cardExpiredInput.addEventListener('input', handleExpiredInput)
cardExpiredInput.addEventListener('keyup', cardReadyHandler)

cardNumberInput.focus({
  preventScroll: true
})

// Card number mask
new Cleave(
  cardNumberInput, {
    creditCard: true,
    onValueChanged: (e) => {
      if(e.target.rawValue.length === 16) {
        cardExpiredInput.focus()
      }
    }
  },
)

// Expired mask
function handleExpiredInput(e) {
  const value = e.target.value.toString().replace(/\D/g, '')
  const length = value.length
  let result = value

  // XX
  if (length === 1 && parseInt(value) > 1) {
    result = '0' + value
  }
  // YYYY -> YY
  if (result.length === 6) {
    result = result.substr(0, 2) + result.substr(-2, 2)
  }
  // Mask result
  if (result.length > 2) {
    result = result.substr(0,2) + '/' + result.substr(2, 2)
  }
  // Update value if something changed
  if (result !== e.target.value) {
    e.target.value = result
  }

  if(result.length === 5) {
    cardCvcInput.focus()
  }
}

// CVC mask
new Cleave(
  cardCvcInput, {
    numeral: true,
    numeralIntegerScale: 3,
    stripLeadingZeroes: false
  },
)
